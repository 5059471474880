import React from 'react'
import { category1, category1Video, category2Video, category3Video, category4Video, category2, category3, category4, } from '../../assets'
import { dataCategory1Video, dataCategory2Video, dataCategory3Video, dataCategory4Video } from '../../constants'
import styles, { layout } from '../../style'
import SwipeSliderVideo from '../swipslider/SwipeSliderVideo'

const VideoStore = () => {
    return (
        <>
            <section id="video" className={layout.section}>
                <div className={layout.sectionInfo}>
                    <h2 className={styles.heading2}>
                        Videoteca
                    </h2>
                </div>
            </section>
            <div className='cont-media-section'>
                {/* <SwipeSliderVideo id={5} title='Oración' imgCategory={category1} data={dataCategory1Video} img={category1Video} />
                <SwipeSliderVideo id={6} title='Meditación' imgCategory={category2} data={dataCategory2Video} img={category2Video} /> */}
                <SwipeSliderVideo id={7} title='Buscar a Dios' imgCategory={category3} data={dataCategory3Video} img={category3Video} />
                {/* <SwipeSliderVideo id={8} title='Nuestra espiritualidad' imgCategory={category4} data={dataCategory4Video} img={category4Video} /> */}
            </div>
        </>
    )
}

export default VideoStore