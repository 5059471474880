
const FeedbackCard = ({ content, title, img }) => (
    <div className="flex justify-between flex-col px-3 py-5 rounded-[20px]  max-w-[230px] md:mr-10 sm:mr-5 mr-0 my-5 feedback-card  ">
        <img src={img} alt="double_quotes" className="w-[70px] h-[70px] object-contain rounded-full" />
        <h4 className="font-poppins font-bold text-[20px] leading-[27px] text-white mt-6 -mb-6">
            {title}
        </h4>
        <p className="font-poppins font-normal text-[17px] leading-[32.4px] text-white my-10">
            {content}
        </p>
    </div>
);


export default FeedbackCard;