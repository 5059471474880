import styles, { layout } from '../../style';
import SwipeSlider from '../swipslider/SwipeSlider';
import { category1, category2, category3, category4} from "../../assets";
import { category1Pdf, category2Pdf, category3Pdf,category4Pdf } from "../../assets/index";
import { dataCategory1, dataCategory2, dataCategory3, dataCategory4 } from '../../constants'; 
import './Bookstore.scss';

const Bookstore = () => {
    return (
        <>
            <section id="library" className={layout.section}>
                <div className={layout.sectionInfo}>
                    <h2 className={styles.heading2}>
                        Biblioteca de <br /> PDF's
                    </h2>
                </div>
            </section>
            <div className='cont-media-section'>
                <SwipeSlider id={1} title='Oración' imgCategory={category1} data={dataCategory1} img={category1Pdf}/>
                {/* <SwipeSlider id={2} title='Meditación' imgCategory={category2} data={dataCategory2} img={category2Pdf}/> */}
                {/* <SwipeSlider id={3} title='Buscar a Dios' imgCategory={category3} data={dataCategory3} img={category3Pdf}/> */}
                <SwipeSlider id={4} title='Nuestra espiritualidad' imgCategory={category4} data={dataCategory4} img={category4Pdf}/>
            </div>

        </>
    )
}

export default Bookstore