import styles from '../style';
import { robot, youtube } from '../assets';

const Hero = () => (
    <section id='home' className={`flex md:flex-row flex-col ${styles.paddingY}`}>
        <div className={`flex-1 flex-col xl:px-0 sm:px-16 px-6`}>
            <div className='flex flex-row justify-between items-center w-full'>
                <h1 className="flex-1 font-poppins font-semibold ss:text-[50px] text-[52px] text-white ss:leading-[90.8px] leading-[75px]">
                    Los que {/* <br className="sm:block hidden" /> */}{" "}
                    <span className="text-gradient">buscan</span>{" "}
                </h1>

            </div>
            <h1 className="font-poppins font-semibold ss:text-[50px] text-[35px] text-white ss:leading-[85.8px] leading-[75px] w-full">
                a <span className="text-gradient text-[50px]">DIOS</span>
            </h1>
            <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
                Una invitación a todos los que desean llegar a disfrutar de una relación íntima, personal y profunda con el Dios único y verdadero a través Su Hijo Jesucristo por medio de la oración y la meditación en la Biblia.
            </p>
            <div className='cont-cta'>
                <p className={`${styles.paragraph2} max-w-[470px] mt-5`}>Podés ver más en mi canal de YouTube</p>
                <img
                    src={youtube}
                    alt='media'
                    className='w-[60px] h-[60px] object-contain cursor-pointer ml-4'
                    onClick={() => window.open('https://www.youtube.com/@miguelangelsingh1594')}
                />
            </div>
        </div>
        <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
            <img src={robot} alt='billing' className='w-[100%] h-[100%] relative z-[5]' />
            <div className='absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient' />
            <div className='absolute z-[1] w-[80%] h-[80%] top-0 white__gradient rounded-full bottom-40' />
            <div className='absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient' />
        </div>
    </section>
)

export default Hero