import styles from './style';
import { Billing, Footer, Navbar, Testimonials, Hero, Bookstore } from "./components";
//import Podcast from './components/podcast/Podcast';
import VideoStore from './components/videos/VideoStore';

const App = () => {
    return (
        <>
            <div className='bg-primary w-full overflow-hidden'>
                <div className={`${styles.paddingX} ${styles.flexCenter}`}>
                    <div className={`${styles.boxWidth}`}>
                        <Navbar />
                    </div>
                </div>

                <div className={`bg-primary ${styles.flexStart}`}>
                    <div className={`bg-primary ${styles.boxWidth}`}>
                        <Hero />
                    </div>
                </div>

                <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
                    <div className={`bg-primary ${styles.boxWidth}`}>
                        {/* <Stats /> */}
                        <Billing /> {/* seccion texto imagen */}
                        <Testimonials />

                        {/* <Business /> */} {/* para el podcast */}
                        {/* <CardDeal /> */}
                        {/* <Billing /> */}
                        {/* <Testimonials />
                    <Clients />
                    <Cta /> */}
                        {/* <Business /> */} {/* seccion imagen texto */}
                    </div>
                </div>

                {/* inicio secciones media */}

                <Bookstore />

                {/* <Podcast /> */}

                <VideoStore />


                {/* fin secciones media */}

                <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
                    <div className={`bg-primary ${styles.boxWidth}`}>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default App