
import { profile } from "../assets";
import styles, { layout } from "../style";

const Billing = () => (
    <section id="about" className={layout.sectionReverse}>

        <div className={layout.sectionImgReverse}>
            <img src={profile} alt="billing" className="w-[100%] h-[100%] relative z-[5] rounded-full" />

            {/* gradient start */}
            <div className="absolute z-[0] w-[60%] h-[60%] left-[100%] top-[-120%] rounded-full blue__gradient bottom-40" />
            {/* gradient end */}
        </div>
        <div className="absolute z-[0] w-[40%] h-[30%] xl:top-[130%] xl:-right-[-30] sm:top-[160%] rounded-full blue__gradient bottom-40" />

        <div className={layout.sectionInfo}>
            <h2 className={styles.heading2}>
                Un Poco sobre mí <br className="sm:block hidden" /> y mi historia
            </h2>
            <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
                Hola! mi nombre es Miguel Singh, bienvenido a mi web personal, desde muy chico
                La búsqueda de Dios fue un anhelo que estaba siempre en mi corazón.
                De muy pequeño surgió este interrogante:
                ¿Cómo hago Dios para conocerte?
                Por el contrario, mi alma sigue sedienta de conocer a Dios, al Dios vivo y Verdadero.
                Hoy ya tengo ochenta años y sigo buscando a Dios con toda mi alma y todo
                mi corazón.
                En esta página intento compartir algo de esta maravillosa experiencia de
                buscar a Dios. Es mi oración que mi experiencia volcada aquí, pueda servir a
                otros que ya están viviendo esta maravillosa y apasionante aventura.
            </p>
        </div>
    </section>
);

export default Billing;