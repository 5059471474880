import { song, PDF, category1, category2, category3, category4, facebook, youtube, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star } from "../assets";

export const navLinks = [
    {
        id: "home",
        title: "Home",
    },
    {
        id: "about",
        title: "Sobre mí",
    },
    {
        id: "purpose",
        title: "¿Qúe Buscamos?",
    },
    {
        id: "library",
        title: "Biblioteca",
    },
    /* {
        id: "podcast",
        title: "Podcast",
    }, */
    {
        id: "video",
        title: "Videoteca",
    },
];

export const dataCategory1 = [
    {
        id: '1',
        title: 'LA ORACIÓN SOLO PARA ALGUNOS',
        urlPdf: 'https://drive.google.com/file/d/1qK-5R6J-F9VZgoD0E4mA-GEhuyY3UDqL/view?usp=sharing',
    },
    /* {
        id: '2',
        title: 'TÍTULO 1',
        urlPdf: PDF,
    },
    {
        id: '3',
        title: 'TÍTULO 1',
        urlPdf: PDF,
    },
    {
        id: '4',
        title: 'TÍTULO 1',
        urlPdf: PDF,
    },
    {
        id: '5',
        title: 'TÍTULO 1',
        urlPdf: PDF,
    },
    {
        id: '7',
        title: 'TÍTULO 1',
        urlPdf: PDF,
    },
    {
        id: '8',
        title: 'TÍTULO 1',
        urlPdf: PDF,
    }, */
]

export const dataCategory2 = [
    {
        id: 1,
        urlPdf: PDF,
        title: 'TÍTULO 2',
    },
    {
        id: 2,
        urlPdf: PDF,
        title: 'TÍTULO 2',
    },
    {
        id: 3,
        urlPdf: PDF,
        title: 'TÍTULO 2',
    },
    {
        id: 4,
        urlPdf: PDF,
        title: 'TÍTULO 2',
    },
    {
        id: 5,
        urlPdf: PDF,
        title: 'TÍTULO 2',
    },
    {
        id: 6,
        urlPdf: PDF,
        title: 'TÍTULO 2',
    },
    {
        id: 7,
        urlPdf: PDF,
        title: 'TÍTULO 2',
    },
    {
        id: 8,
        urlPdf: PDF,
        title: 'TÍTULO 2',
    }
];

export const dataCategory3 = [
    {
        id: 1,
        urlPdf: PDF,
        title: 'TÍTULO 3',
    },
    {
        id: 2,
        urlPdf: PDF,
        title: 'TÍTULO 3',
    },
    {
        id: 3,
        urlPdf: PDF,
        title: 'TÍTULO 3',
    },
    {
        id: 4,
        urlPdf: PDF,
        title: 'TÍTULO 3',
    },
    {
        id: 5,
        urlPdf: PDF,
        title: 'TÍTULO 3',
    },
    {
        id: 6,
        urlPdf: PDF,
        title: 'TÍTULO 3',
    },
    {
        id: 7,
        urlPdf: PDF,
        title: 'TÍTULO 3',
    },
    {
        id: 8,
        urlPdf: PDF,
        title: 'TÍTULO 3',
    }
];

export const dataCategory4 = [
    {
        id: 1,
        urlPdf: 'https://drive.google.com/file/d/1KmLHMP6_XeICXDNEl9WAPrRaFZ7144mR/view?usp=sharing',
        title: 'UN ENSAYO SOBRE LA ESPIRITUALIDAD',
    },
    {
        id: 2,
        urlPdf: 'https://drive.google.com/file/d/1bL844aC64ek-iylZ3ExqQI4v-JbkzqnC/view?usp=sharing',
        title: 'RESUMEN DE NUESTRA VISIÓN',
    },
    {
        id: 3,
        urlPdf: 'https://drive.google.com/file/d/1R3ea9RGEcN1HIIC9pqLokBAIDQ8Ymrzk/view?usp=sharing',
        title: 'LA FAMILIA CRISTIANA EN EL CONTEXTO ACTUAL',
    },
    /* {
        id: 4,
        urlPdf: PDF,
        title: 'TÍTULO 4',
    },
    {
        id: 5,
        urlPdf: PDF,
        title: 'TÍTULO 4',
    },
    {
        id: 6,
        urlPdf: PDF,
        title: 'TÍTULO 4',
    },
    {
        id: 7,
        urlPdf: PDF,
        title: 'TÍTULO 4',
    },
    {
        id: 8,
        urlPdf: PDF,
        title: 'TÍTULO 4',
    } */
];

export const dataCategory1Audio = [
    {
        id: 1,
        src: song,
        title: "Podcast-1",
        artist: "Artist-1"
    },
    {
        id: 2,
        src: song,
        title: "Podcast 2",
        artist: "Artist-2"
    },
    {
        id: 3,
        src: song,
        title: "Podcast 3",
        artist: "Artist-3"
    }
]

export const dataCategory2Audio = [
    {
        id: 1,
        src: song,
        title: "Podcast-1",
        artist: "Artist-1"
    },
    {   
        id: 2,
        src: song,
        title: "Podcast 2",
        artist: "Artist-2"
    },
    {
        id: 3,
        src: song,
        title: "Podcast 3",
        artist: "Artist-3"
    }
]

export const dataCategory3Audio = [
    {
        id: 1,
        src: song,
        title: "Podcast-1",
        artist: "Artist-1"
    },
    {
        id: 2,
        src: song,
        title: "Podcast 2",
        artist: "Artist-2"
    },
    {
        id: 3,
        src: song,
        title: "Podcast 3",
        artist: "Artist-3"
    }
]

export const dataCategory4Audio = [
    {
        id: 1,
        src: song,
        title: "Podcast-1",
        artist: "Artist-1"
    },
    {
        id: 2,
        src: song,
        title: "Podcast 2",
        artist: "Artist-2"
    },
    {
        id: 3,
        src: song,
        title: "Podcast 3",
        artist: "Artist-3"
    }
]

export const dataCategory1Video = [
    {
        id: '1',
        title: 'TÍTULO 1',
        urlVideo: 'vr0qNXmkUJ8',
    },
    {
        id: '2',
        title: 'TÍTULO 1',
        urlVideo: 'A3PDXmYoF5U',
    },
    {
        id: '3',
        title: 'TÍTULO 1',
        urlVideo: 'vr0qNXmkUJ8',
    },
    {
        id: '4',
        title: 'TÍTULO 1',
        urlVideo: 'A3PDXmYoF5U',
    },
    {
        id: '5',
        title: 'TÍTULO 1',
        urlVideo: 'A3PDXmYoF5U',
    },
    {
        id: '7',
        title: 'TÍTULO 1',
        urlVideo: 'vr0qNXmkUJ8',
    },
    {
        id: '8',
        title: 'TÍTULO 1',
        urlVideo: 'A3PDXmYoF5U',
    },
]

export const dataCategory2Video = [
    {
        id: '1',
        title: 'TÍTULO 2',
        urlVideo: 'vr0qNXmkUJ8',
    },
    {
        id: '2',
        title: 'TÍTULO 2',
        urlVideo: 'A3PDXmYoF5U',
    },
    {
        id: '3',
        title: 'TÍTULO 2',
        urlVideo: 'vr0qNXmkUJ8',
    },
    {
        id: '4',
        title: 'TÍTULO 2',
        urlVideo: 'A3PDXmYoF5U',
    },
    {
        id: '5',
        title: 'TÍTULO 2',
        urlVideo: 'A3PDXmYoF5U',
    },
    {
        id: '7',
        title: 'TÍTULO 2',
        urlVideo: 'vr0qNXmkUJ8',
    },
    {
        id: '8',
        title: 'TÍTULO 2',
        urlVideo: 'A3PDXmYoF5U',
    },
]

export const dataCategory3Video = [
    {
        id: '1',
        title: 'POR QUÉ BUSCAMOS A DIOS',
        urlVideo: 'O4YinqaYVhI',
    },
    {
        id: '2',
        title: 'LA DECISIÓN DE DEJAR DE PECAR',
        urlVideo: 'fvNZRvL9iao',
    },
    {
        id: '3',
        title: 'DESCANSAR EN DIOS',
        urlVideo: 'LffV5lT6Obk',
    },
    {
        id: '4',
        title: 'INTEGRIDAD DELANTE DE DIOS',
        urlVideo: 'w9EjJTwGkC0',
    },
    {
        id: '5',
        title: 'PERMANECER EN CRISTO',
        urlVideo: 'H5e3tFwZVXk',
    },
    {
        id: '6',
        title: 'MEDITACIÓN CRISTIANA',
        urlVideo: 'ugoIlNHx5TY',
    },
    {
        id: '7',
        title: 'MEDITANDO LA PALABRA DE DIOS',
        urlVideo: 'fhAzrByigy8',
    }
]

export const dataCategory4Video = [
    {
        id: '1',
        title: 'TÍTULO 4',
        urlVideo: 'vr0qNXmkUJ8',
    },
    {
        id: '2',
        title: 'TÍTULO 4',
        urlVideo: 'A3PDXmYoF5U',
    },
    {
        id: '3',
        title: 'TÍTULO 4',
        urlVideo: 'vr0qNXmkUJ8',
    },
    {
        id: '4',
        title: 'TÍTULO 4',
        urlVideo: 'A3PDXmYoF5U',
    },
    {
        id: '5',
        title: 'TÍTULO 4',
        urlVideo: 'A3PDXmYoF5U',
    },
    {
        id: '7',
        title: 'TÍTULO 4',
        urlVideo: 'vr0qNXmkUJ8',
    },
    {
        id: '8',
        title: 'TÍTULO 4',
        urlVideo: 'A3PDXmYoF5U',
    },
]


export const features = [
    {
        id: "feature-1",
        icon: star,
        title: "Rewards",
        content:
            "The best credit cards offer some tantalizing combinations of promotions and prizes",
    },
    {
        id: "feature-2",
        icon: shield,
        title: "100% Secured",
        content:
            "We take proactive steps make sure your information and transactions are secure.",
    },
    {
        id: "feature-3",
        icon: send,
        title: "Balance Transfer",
        content:
            "A balance transfer credit card can save you a lot of money in interest charges.",
    },
];

export const feedback = [
    {
        id: "feedback-1",
        content:"Encontrarás cursos, videos y pdf que tratan sobre este tema.",
        title: "ORACIÓN",
        img: category1,
    },
    {
        id: "feedback-2",
        content:"Abundante información sobre el fundamento y la práctica de meditar en la Palabra de Dios",
        name: "Steve Mark",
        title: "MEDITACIÓN",
        img: category2,
    },
    {
        id: "feedback-3",
        content:
            "Experiencias, testimonios y enseñanzas sobre la búsqueda de Dios.",
        name: "Steve Mark",
        title: "BUSCAR A DIOS",
        img: category3,
    },
    {
        id: "feedback-4",
        content:
            "Escritos, comentarios, testimonios sobre las profundidades espirituales.",
        name: "Steve Mark",
        title: "NUESTRA ESPIRITUALIDAD",
        img: category4,
    },
];

export const stats = [
    {
        id: "stats-1",
        title: "User Active",
        value: "3800+",
    },
    {
        id: "stats-2",
        title: "Trusted by Company",
        value: "230+",
    },
    {
        id: "stats-3",
        title: "Transaction",
        value: "$230M+",
    },
];

export const footerLinks = [
    {
        title: "Useful Links",
        links: [
            {
                name: "Content",
                link: "https://www.hoobank.com/content/",
            },
            {
                name: "How it Works",
                link: "https://www.hoobank.com/how-it-works/",
            },
            {
                name: "Create",
                link: "https://www.hoobank.com/create/",
            },
            {
                name: "Explore",
                link: "https://www.hoobank.com/explore/",
            },
            {
                name: "Terms & Services",
                link: "https://www.hoobank.com/terms-and-services/",
            },
        ],
    },
    {
        title: "Community",
        links: [
            {
                name: "Help Center",
                link: "https://www.hoobank.com/help-center/",
            },
            {
                name: "Partners",
                link: "https://www.hoobank.com/partners/",
            },
            {
                name: "Suggestions",
                link: "https://www.hoobank.com/suggestions/",
            },
            {
                name: "Blog",
                link: "https://www.hoobank.com/blog/",
            },
            {
                name: "Newsletters",
                link: "https://www.hoobank.com/newsletters/",
            },
        ],
    },
    {
        title: "Partner",
        links: [
            {
                name: "Our Partner",
                link: "https://www.hoobank.com/our-partner/",
            },
            {
                name: "Become a Partner",
                link: "https://www.hoobank.com/become-a-partner/",
            },
        ],
    },
];

export const socialMedia = [
    {
        id: "social-media-1",
        icon: youtube,
        link: "https://www.youtube.com/@miguelangelsingh1594",
    },
    /* {
        id: "social-media-2",
        icon: facebook,
        link: "https://www.facebook.com/",
    },
    {
        id: "social-media-3",
        icon: twitter,
        link: "https://www.twitter.com/",
    },
    {
        id: "social-media-4",
        icon: linkedin,
        link: "https://www.linkedin.com/",
    }, */
];

export const clients = [
    {
        id: "client-1",
        logo: airbnb,
    },
    {
        id: "client-2",
        logo: binance,
    },
    {
        id: "client-3",
        logo: coinbase,
    },
    {
        id: "client-4",
        logo: dropbox,
    },
];