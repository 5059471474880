import React from 'react'
import CardVideo from '../cards/cardVideo/CardVideo'
import './style.scss';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

const SwipeSliderVideo = ({id, title, imgCategory, data, img}) => {

    const slideLeft = () => {
        let slider = document.getElementById(id)
        slider.scrollLeft = slider.scrollLeft - 500
    }

    const slideRight = () => {
        let slider = document.getElementById(id)
        slider.scrollLeft = slider.scrollLeft + 500
    }

    return (
        <div className='swiper-container'>
            <div className='header mt-1'>
                <img src={imgCategory} alt="double_quotes" className="w-[40px] h-[40px] object-contain rounded-full" />
                <h4 className="font-poppins font-bold text-[20px] text-white custom-title ml-2">
                    { title }
                </h4>
            </div>

            <div className='relative flex items-center'>
                <IoChevronBackOutline className="opacity-50 cursor-pointer hover:opacity-100" color='white' size={40} onClick={slideLeft} />
                <div id={id} className='w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide'>
                    {
                        data.map((item) => (
                            <CardVideo key={item.id} title={item.title} img={img} videoUrl={item.urlVideo}/>
                        ))
                    }
                </div>
                <IoChevronForwardOutline className="opacity-50 cursor-pointer hover:opacity-100" color='white' size={40} onClick={slideRight} />
            </div>
        </div>
    )
}

export default SwipeSliderVideo