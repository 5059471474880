import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';

const CardVideo = ({ title, img, videoUrl }) => {

    const [isOpen, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true)
    }

    return (
        <>
            <div className='inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300 card' onClick={handleClick}>
                <img
                    className='w-[360px] h-[180px]'
                    src={img}
                    alt='carousel'
                />
                <div className='cont-header'>
                    <h4 className='header'> {title} </h4>
                </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={videoUrl} onClose={() => setOpen(false)} />
        </>
    )
}

export default CardVideo