import React from 'react'
import '../Cards.scss';

const CardPdf = ({title, img, pdfUrl}) => {
    return (
        // eslint-disable-next-line
        <a href={pdfUrl} target='_blank' download>
            <div className='inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300 card'>       
                <img
                    className='w-[360px] h-[180px]'
                    src={img}
                    alt='carousel'
                />
                <div className='cont-header'>
                    <h4 className='header'> {title} </h4>
                </div>
            </div>
        </a>
    )
}

export default CardPdf